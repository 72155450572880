::-webkit-scrollbar{
    width: 15px;
}

::-webkit-scrollbar-track{
    background: #fff;
}

::-webkit-scrollbar-thumb{
    background-image: linear-gradient(#eeeeee, #eeeeee);
}

* {
    box-sizing: border-box;
}